import { channel } from 'redux-saga';
import { all, put, takeEvery, takeLatest, call, delay, take, fork } from 'redux-saga/effects';
import * as t from '../action_types';
import * as actions from '../action_creators';
import { setCookie, removeCookie, is_production } from '../../util_functions';
import { post_api, get_api } from '../api_funcs';
import { get_api_url } from '../../utils/urls';

export function* logInApp(action) {
  try {
    yield put(actions.reset());
    yield put(actions.set_home_variable('login_status', 'loading'));
    const url = get_api_url('login_app');
    const payload = {
      ...action.payload
    };
    const response = yield call(post_api, url, payload);
    yield call(setCookie, 'cms_token_3', response.data.token);
    yield call(setCookie, 'care_chat_token', response.data.care_chat_token);
    yield call(setCookie, 'care_uuid', response.data.care_uuid);
    yield call(setCookie, 'cms_email', payload.email);
    yield call(setCookie, 'cms_user_confirmation', true, 157680000);
    yield put(actions.set_home_variable('cms_token', response.data.token));
    yield put(actions.set_home_variable('login_status', 'success'));
  } catch (error) {
    yield put(actions.set_home_variable('login_error_message', error.response.data.message));
    yield put(actions.set_home_variable('login_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}

export function* getDashboardData() {
  try {
    yield put(actions.set_home_variable('dashboard_status', 'loading'));
    const url = get_api_url('get_dashboard_data');
    const response = yield call(post_api, url, null, true);
    yield put(actions.set_home_variable('dashboard_data', response.data));
    yield put(actions.set_home_variable('dashboard_status', 'success'));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_home_variable('dashboard_status', 'fail'));
  }
}

export function* impersonateUser(action) {
  yield put(actions.set_teachers_variable('teacher_profile_status', 'loading'));
  try {
    if (action.payload.client_domain === 'backpain') {
      const url = get_api_url('get_impersonation_token');
      const payload = { student_uuid: action.payload.user_uuid };
      const response = yield call(post_api, url, payload, true);
      if (response.status === 200) {
        const web_url = is_production()
          ? 'https://www.lifetreehealth.com'
          : 'https://testenv1.lifetreehealth.com';
        window.open(`${web_url}/auth-user?token=${response.data.token}`);
      }
    } else {
      const url = get_api_url('impersonate_myt_user');
      const payload = {
        user_uuid: action.payload.user_uuid,
        user_type: action.payload.user_type
      };
      const response = yield call(post_api, url, payload, true);
      yield call(setCookie, 'is_impersonated', true);
      if (action.payload.user_type === 'TEACHER') {
        yield call(setCookie, 'teacher_token_1', response.data.token);
        yield call(setCookie, 'is_admin_imp', true);
        const web_url = is_production()
          ? 'https://teacher.myyogateacher.com'
          : 'https://teacher-testenv1.myyogateacher.com';
        window.open(`${web_url}/home`);
      } else {
        yield call(setCookie, 'cms_iproae', true, 86400, action.payload.client_domain);
        if (action.payload.is_uk_user === 1) {
          yield call(setCookie, 'cms-country-code', 'GB', 600);
        } else yield call(removeCookie, 'cms-country-code');
        yield call(setCookie, 'student_token', response.data.token);
        yield call(setCookie, 'student_waiting_queue', 0);
        setTimeout(() => {
          const web_url = is_production()
            ? 'https://myyogateacher.com/dashboard'
            : 'https://student-testenv4.myyogateacher.com/dashboard';
          window.open(`${web_url}`);
        }, 300);
      }
    }
    yield put(actions.set_teachers_variable('teacher_profile_status', 'none'));
  } catch (error) {
    yield put(actions.set_teachers_variable('teacher_profile_status', 'fail'));
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
  }
}

export function* logOut() {
  removeCookie('cms_token_3');
  removeCookie('cms_email');
  yield put(actions.set_home_variable('cms_token', ''));
}

export function* getPermissions(action) {
  try {
    yield put(actions.set_loading_variable('permissions_status', 'loading'));
    const url = get_api_url('/v2/cms_user/get_accessible_pages', false, true);
    const response = yield call(get_api, url, true);

    yield put(actions.set_home_variable('access_pages', [...response.data.data.accessible_pages]));
    yield put(actions.set_home_variable('access_type', response.data.data.access_type));
    yield put(actions.set_home_variable('cms_teacher_uuid', response.data.data.cms_teacher_uuid));
    yield put(actions.set_home_variable('cms_user_name', response.data.data.cms_user_name));
    yield put(actions.set_loading_variable('permissions_status', 'success'));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_loading_variable('permissions_status', 'fail'));
  }
}

export function* getConfig(action) {
  try {
    yield put(actions.set_loading_variable('config_status', 'loading'));
    const url = get_api_url('cms_config.json');
    const response = yield call(post_api, url, null, true);
    yield put(actions.set_home_variable('config_values', { ...response.data }));
    yield put(actions.set_loading_variable('config_status', 'success'));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_loading_variable('config_status', 'fail'));
  }
}

export function* testSaga(payload) {
  yield delay(100);
}

function* watchRequests() {
  const chan = yield call(channel);
  for (let i = 0; i < 10; i++) {
    yield fork(handleRequest, chan);
  }
  while (true) {
    const { payload } = yield take(t.test_saga);
    yield put(chan, payload);
  }
}

function* handleRequest(chan) {
  while (true) {
    const payload = yield take(chan);
    yield delay(500);
  }
}

function* getClassTypes(action) {
  try {
    yield put(actions.set_loading_variable('class_types_status', 'loading'));
    const url = get_api_url('get_class_types');
    const { payload } = action;
    const response = yield call(post_api, url, payload, true);
    const keywords = [];
    for (const keyword of response.data.health_keywords) {
      keywords.push(keyword.keyword);
    }
    const class_types = [...response.data.group_classes].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    yield put(actions.set_home_variable('class_types', class_types));
    yield put(actions.set_home_variable('health_keywords', [...keywords]));
    yield put(
      actions.set_home_variable('health_keywords_data', [...response.data.health_keywords])
    );
    yield put(actions.set_loading_variable('class_types_status', 'success'));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(actions.log_out());
    }
    yield put(actions.set_loading_variable('class_types_status', 'fail'));
  }
}

export default function* chatSaga() {
  yield all([
    takeLatest(t.login_to_app, logInApp),
    takeLatest(t.get_dashboard_data, getDashboardData),
    takeLatest(t.log_out, logOut),
    takeEvery(t.impersonate_user, impersonateUser),
    takeEvery(t.get_permissions, getPermissions),
    takeLatest(t.get_config, getConfig),
    takeLatest(t.get_class_types, getClassTypes),
    watchRequests()
    // takeEvery(t.test_saga, testSaga)
  ]);
}
